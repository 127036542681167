
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckOlderComponent } from './check-older.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [CheckOlderComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule
  ],

  exports: [
    CheckOlderComponent
  ]
})

export class CheckOlderModule { }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-address-selected',
  templateUrl: './card-address-selected.component.html',
  styleUrls: ['./card-address-selected.component.scss']
})
export class CardAddressSelectedComponent implements OnInit {

  
  @Input() address;
  @Input() selectedAddressId;
  @Output() emitAddress = new EventEmitter();

  selected = false;
  mouseover = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.selected = this.address?._id == this.selectedAddressId ? true : false;
  }

  selectAddress(address: any) {
    this.emitAddress.emit(address);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class SellerService extends CrudService{
  endpoint = 'sellers';
  url = environment.apiUrl;


  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }
}

import { MatListModule } from '@angular/material/list';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { ListAddressSelectComponent } from "./list-address-select.component";
import { MatCardModule } from '@angular/material/card';


@NgModule({
  declarations: [
    ListAddressSelectComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatCardModule
  ],
  exports: [
    ListAddressSelectComponent
  ]
})
export class ListAddressSelectModule { }

<mat-card class="card-body m-2"
(mouseover)="mouseover=true" (mouseout)="mouseover=false" (click)="selectAddress(address)"
[ngClass]="{'card-selected mat-elevation-z6': selected, 'mat-elevation-z4' : mouseover}">
    <span class="selection">
        <mat-icon>check_circle</mat-icon>
    </span>

    <div class="card-title">
        <p class="m-0" style="font-weight: bold;">{{address.receiver_name}}</p>
    </div>

    <mat-divider></mat-divider>

    <p class="full_address card-text">{{address.address?.full_address}}</p>
</mat-card>

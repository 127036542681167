import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})

export class ShippingService extends CrudService{
  endpoint = 'shipping';
  url = environment.apiUrl;


  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }

  public findShippingCalculate( objParams: any ): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/calculate`, objParams).toPromise()
  }

  public findShippingCalculateAll( objParams: any ): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/calculateAll`, objParams).toPromise()
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends CrudService{

  url = environment.apiUrl;
  endpoint = 'orders';

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }

  myOrders(customer_id: string, status: any): Promise<any> {
    let params = new HttpParams();
    if(customer_id){
      params = params.append('customer_id', customer_id);
    }
    if(status?.length > 0){
      status.forEach((element, i) => {
        params = params.append('statuses['+ i+ ']', element );
      });
    }
    params = params.append('limit', '1000');
    return this.http.get(`${this.url}/${this.endpoint}/myOrders/forCustomer`, {params}).toPromise();
  }

  getTracking(order_id): Promise<any> {
    return this.http.get(`${this.url}/${this.endpoint}/${order_id}/shipping/tracking`).toPromise()
  }

  getChatByOrderAndSeller(order_id, seller_id): Promise<any> {
    return this.http.get(`${this.url}/${this.endpoint}/${order_id}/contactSeller/${seller_id}`).toPromise()
  }




}
